<template>
  <div class="footer-menu">
    <div class="footer-menu-logo">
      <router-link to="/">
        <img class="logo" src="/images/logo.svg" alt="Austin Robbins Logo" />
      </router-link>
    </div>
    <div class="footer-menu-items">
      <router-link to="/">Home</router-link>
      <router-link to="/about"> About/Jobs </router-link>
      <router-link to="/team"> Our Team </router-link>
      <router-link to="/contact"> Contact </router-link>
    </div>
    <div class="footer-menu-claims-form-button">
      <router-link class="btn" to="/claimsform">
        <img src="/images/icon/letter.svg" />
        Claims Form
      </router-link>
    </div>
  </div>
</template>

<script>
export default {
  name: "FooterMenu",
};
</script>
<style scoped lang="scss">
.footer-menu {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  padding: 40px 60px;
}
.footer-menu-logo {
  img {
    height: 61px;
  }
}
.footer-menu-items {
  display: flex;
  flex-wrap: wrap;
  a {
    display: block;
    font-family: $secondaryFont;
    color: #fff;
    text-transform: uppercase;
    width: 50%;
    text-decoration: none;
    letter-spacing: 1.1px;
    font-size: 11px;
    line-height: 13px;
    margin-top: 15px;
  }
}
.footer-menu-claims-form-button {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}

@media screen and (max-width: $tabletWidth) {
}

@media screen and (max-width: $mobile) {
  .footer-menu > div {
    width: 100%;
  }
  .footer-menu-logo {
    img {
      max-width: 100%;
    }
  }
  .footer-menu-items {
    text-align: center;
  }
  .footer-menu-claims-form-button {
    margin-top: 40px;
  }
}
</style>
