<template>
  <div
    class="top-nav mobile-top-nav"
    v-bind:class="{ 'sticky-nav': stickyNav, 'white-border': whiteBorder}"
    
  >
    <router-link class="home" to="/">
      <img class="logo" src="/images/logo.svg" alt="Austin Robbins Logo" />
    </router-link>
    <div class="desktop-menu mene-items-container">
      <router-link to="/services"> Services </router-link>
      <router-link to="/about"> About/Jobs </router-link>
      <router-link v-bind:class="{ active: onMemberPage }" to="/team">
        Our Team
      </router-link>
      <router-link to="/contact"> Contact </router-link>
      <router-link class="btn" to="/claimsform">
        <img src="/images/icon/letter.svg" />
        Claims Form
      </router-link>
    </div>
    <button class="mobile-menu" @click="menuShow = true">
      <img class="menu-open" src="/images/menu-open.png" alt="Menu Open" />
    </button>
    <transition
      name="fade"
      enter-active-class="animate__animated animate__fadeIn"
      leave-active-class="animate__animated animate__fadeOut"
    >
      <div class="menu-container" v-show="menuShow">
        <button class="mobile-menu" @click="menuShow = false">
          <img
            class="menu-close"
            src="/images/menu-close.png"
            alt="Menu Open"
          />
        </button>
        <div class="content">
          <router-link
            v-for="(item, i) in menuItems"
            v-bind:key="i"
            v-bind:to="item.link"
          >
            <span @click="closeMenu" role="link">{{ item.label }}</span>
          </router-link>
          <router-link class="btn" to="/claimsform">
            <img src="/images/icon/letter.svg" />
            <span @click="closeMenu"> Claims Form </span>
          </router-link>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  name: "Menu",
  methods: {
    closeMenu() {
      this.menuShow = false;
    },
    // handleScroll() {
    //   this.scrollYPos = window.scrollY;
    // },
  },
  data() {
    return {
      scrollYPos: 0,
      menuShow: false,
      menuItems: [
        {
          label: "Home",
          link: "/",
        },
        {
          label: "Services",
          link: "/services",
        },
        {
          label: "About/Jobs",
          link: "/about",
        },
        {
          label: "Our Team",
          link: "/team",
        },
        {
          label: "Contact",
          link: "/contact",
        },
      ],
    };
  },
  computed: {
    stickyNav() {
      if (
        this.$route.name !== "Home" &&
        this.$route.name !== "Services" &&
        this.$route.name !== "About" &&
        this.$route.name !== "Team" &&
        this.$route.name !== "Job" 
      ) {
        return true;
      }
      return false;
    },
    whiteBorder() {
      return this.$route.name === "Job" 
    },
    onMemberPage() {
      return this.$route.name === "Team Member";
    },
  },
  // created() {
  //   window.addEventListener("scroll", this.handleScroll);
  // },
  // unmounted() {
  //   window.removeEventListener("scroll", this.handleScroll);
  // },
};
</script>

<style scoped lang="scss">
button {
  background: none;
  border: none;
  position: absolute;
  right: 20px;
  &:hover {
    cursor: pointer;
  }
  img {
    height: 11px;
    width: 43px;
  }
  .menu-close {
    width: 39px;
    height: 39px;
  }
}
.white-border{
  border-bottom: 1px solid rgba(255,255,255, 0.5);
}
.top-nav {
  position: relative;
  top: 0;
  background: transparent;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 63px;
  height: 136px;
  z-index: 10;
  .logo {
    display: block;
    width: auto;
    height: 61px;
  }
  .mene-items-container {
    display: flex;
    flex-direction: row;
    gap: 40px;
    align-items: center;
    justify-content: flex-end;
  }
  a:not(.btn, .home) {
    font-family: $secondaryFont;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: 1.1px;
    color: white;
    text-transform: uppercase;
    text-decoration: none;
    transition: 0.2s;
    &.active {
      border-bottom: 3px solid #0267ff;
    }
    &:hover {
      border-bottom: 3px solid #0267ff;
    }
  }
}

.top-nav.sticky-nav {
  position: sticky;
  background: url("/images/menu-background.png");
  border-bottom: 1px solid #2e3646;
}

.mobile-menu {
  display: none;
  position: sticky;
}
.menu-container {
  position: fixed;
  width: 100%;
  height: 100%;
  margin: 0;
  top: 0;
  left: 0;
  display: block;
  background-color: $primaryColor;
  text-align: center;
  z-index: 20;
  color: #fff;
  .top {
    .logo-in-menu {
      margin: 33px auto 0;
    }
  }
  button {
    position: absolute;
    top: 20px;
  }

  .content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 60px;
    height: 80%;
    a {
      color: #fff;
      text-transform: uppercase;
      text-decoration: none;
      margin: 20px 0;
    }
  }
}

@media screen and (max-width: $tabletWidth) {
  .top-nav.mobile-top-nav {
    padding: 0 20px;
    height: 84px;
    background: #000;
    position: sticky;
  }
  .mobile-menu {
    display: block;
  }
  button {
    .menu-close {
      width: 30px;
      height: 30px;
    }
  }
  .top-nav {
    .desktop-menu {
      display: none;
    }
    justify-content: flex-start;
    .logo {
      margin-top: 0;
      height: 38px;
      margin-left: 20px;
    }
    button {
      position: absolute;
      right: 10px;
      min-width: 0 !important;
      &:hover {
        background: none !important;
      }
      .menu-open {
        width: 40px;
      }
    }
  }
  .menu-container {
    .content {
      a {
        color: #fff;
        text-transform: uppercase;
        text-decoration: none;
        font-size: 25px;
        margin: 30px 0;
      }
    }
    button {
      position: absolute;
      top: 30px;
      right: 15px;
    }
    .top {
      display: none;
    }
    .bottom {
      position: relative;
      bottom: 0px;
      display: flex;
      padding: 0;
      flex-wrap: wrap;
      flex-direction: column;
      margin-top: 20px;
      .box {
        flex: 1;
        display: flex;
        justify-content: center;
        margin-top: 30px;
      }
      .box:first-child > a {
        margin-right: unset;
      }
      .box:last-child > a {
        margin-left: unset;
      }
      .link-div {
        margin-left: unset;
      }
      a {
        color: #fff;
        text-decoration: none;
        font-size: 24px;
        line-height: 24px;
        display: flex;
        align-items: center;
        svg {
          margin-top: 3px;
          margin-right: 8px;
        }
      }
    }
  }
}
@media screen and (max-width: $mobile) {
}
</style>
