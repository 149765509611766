<template>
  <div class="footer">
    <footer-menu />
    <footer-credits />
  </div>
</template>

<script>
import FooterMenu from "./FooterMenu.vue";
import FooterCredits from "./FooterCredits.vue";

export default {
  name: "Footer",
  components: {
    FooterCredits,
    FooterMenu,
  },
};
</script>
<style scoped lang="scss">
.footer {
  background: #1e1e1e;
}
</style>
