<template>
  <div class="footer-credits">
    <p>© {{ currentYear }} PCA Adjusters.</p>
    <p>
      Site by
      <a
        href="https://www.austinrobbins.com/"
        target="_blank"
        rel="noopener noreferrer"
        >Austin Robbins Agency</a
      >
    </p>
  </div>
</template>

<script>
export default {
  name: "FooterCredits",
  data() {
    return {
      currentYear: new Date().getFullYear(),
    };
  },
};
</script>

<style scoped lang="scss">
.footer-credits {
  display: flex;
  justify-content: center;
}
p,
a {
  font-family: $secondaryFont;
  font-weight: 300;
  color: #afafaf;
  font-size: 11px;
  line-height: 28px;
  text-decoration: none;
  &:first-child {
    margin-right: 20px;
  }
}
</style>
