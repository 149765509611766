<template>
  <section class="homevideo">
    <div class="homevideo__container">
      <div class="homevideo__background">
        <div v-if="!imageURL" class="homevideo__background__video">
          <figure class="homevideo__background__asset">
            <video
              autoplay
              class="video"
              data-load="objectFit"
              loop
              muted
              playsinline
            >
              <source
                :data-src="videoURL ? videoURL : defaultVideoURL"
                type="video/mp4"
                :src="videoURL ? videoURL : defaultVideoURL"
              />
            </video>
          </figure>
        </div>
        <div v-if="imageURL" class="homevideo__background__video">
          <figure class="homevideo__background__asset">
            <img :src="imageURL" />
          </figure>
        </div>
      </div>
      <div class="homevideo__gradient"></div>
      <div class="homevideo__foreground">
        <div class="homevideo__content">
          <slot></slot>
          <p v-if="subtitle" v-html="subtitle"></p>
          <router-link :to="downArrowTo" class="ca3-scroll-down-link">
            <img
              src="/images/arrow-down.png"
              alt=""
              class="ca3-scroll-down-arrow"
            />
          </router-link>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "VideoBackground",
  props: {
    videoURL: String,
    title: String,
    subtitle: String,
    imageURL: String,
  },
  data() {
    return {
      defaultVideoURL:
        "https://api.uptowncreative.io/uploads/_/assets/chadrogers/pca-home.mp4",
      defaultTitle: "Adjusting to Meet Your Needs",
      downArrowTo: "",
      isloading: true,
    };
  },
};
</script>

<style lang="scss" scoped>
.homevideo {
  &__container {
    width: 100%;
    min-height: 480px;
    height: 100vh;
    margin-top: -136px;
    margin-bottom: 0;
  }
  &__background {
    &__video {
      display: block;
      &.isloading {
        background: url("/images/initial-slide.jpg") cover;
      }
    }
    &__asset {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      .video {
        position: relative;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
      img {
        position: relative;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    &__mobile {
      display: none;
      img {
        height: 100%;
        width: 100%;
        object-fit: cover;
      }
    }
  }
  &__gradient {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-image: linear-gradient(to bottom, #000, #000);
    opacity: 0.35;
  }
  &__foreground {
    position: absolute;
    top: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    height: 100%;
    color: #fff;
  }
  .homevideo__content {
    color: #fff;
    display: flex;
    flex-direction: column;
    text-align: center;
    justify-content: center;
    ::v-deep h1 {
      margin: 0 auto;
      margin-top: 5%;
      max-width: 70%;
      span {
        font-size: 20px;
        vertical-align: bottom;
      }
    }
    p {
      margin: 0 auto;
      margin-top: 20px;
      max-width: 700px;
    }
    .btn {
      font-family: $secondaryFont;
      width: 200px;
      height: 64px;
      border: none;
      border-radius: 40px;
      border: 1px solid #fff;
      text-align: center;
      text-transform: uppercase;
      text-decoration: none;
      color: #fff;
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 50px auto 0;
      font-size: 16px;
      letter-spacing: 0.05em;
    }
  }
}
figure {
  padding: 0;
  margin: 0;
}

@media screen and (max-width: $tabletWidth) {
  .homevideo {
    &__container {
      width: 100%;
      min-height: 480px;
      height: 100vh;
      margin-top: -84px;
      margin-bottom: 0;
    }
    .homevideo__content {
      margin: 0 auto;
      ::v-deep h1 {
        font-size: 36px;
        line-height: 50px;
        margin: 0 20px;
        max-width: unset;
      }
      p {
        font-size: 20px;
        line-height: 24px;
        margin: 0 20px;
        margin-top: 40px;
      }
    }
  }
}
</style>
