<template>
  <div class="home">
    <video-background
      subtitle='"In todays business environment marked by uncertainty, confusion, fluctuation, and increased consumer demand...PCA Adjusters are always "Adjusting To Meet Your Needs™"'
    >
      <h1>Adjusting to Meet Your Needs<span>™</span></h1>
    </video-background>
    <div class="home-intro">
      <img
        data-aos="fade-up"
        class="badge"
        src="/images/bestmark.png"
        alt="Badge"
      />
      <div class="title-container">
        <h2 data-aos="fade-up">
          Qualified Member of Best's
          <br />
          Recommended Insurance Adjusters.
        </h2>
        <a
          data-aos="fade-up"
          class="btn"
          href="https://claimsresource.ambest.com/search/CompanyDisplay.aspx?dpid=23448&nid=5"
          target="_blank"
        >
          Learn More
          <img class="arrow-right" src="/images/icon/arrow-right.svg" />
        </a>
      </div>
      <div v-if="false" class="service-stats">
        <div class="stat">
          <p class="index">01.</p>
          <div class="numbers">
            <UseElementVisibility v-slot="{ isVisible }">
              <count-up
                :autoplay="isVisible"
                :options="CountUpOptions"
                :startVal="0"
                :end-val="50"
              ></count-up>
            </UseElementVisibility>
            <p>Years of Success</p>
          </div>
          <hr />
          <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi
            tristique mattis nulla, in facilisis justo tincidunt eget.
          </p>
        </div>
        <div class="stat">
          <p class="index">02.</p>
          <div class="numbers">
            <UseElementVisibility v-slot="{ isVisible }">
              <count-up
                :autoplay="isVisible"
                :options="CountUpOptions"
                :startVal="0"
                :end-val="453"
              ></count-up>
            </UseElementVisibility>
            <p>Clients Served</p>
          </div>
          <hr />
          <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi
            tristique mattis nulla, in facilisis justo tincidunt eget.
          </p>
        </div>
        <div class="stat">
          <p class="index">03.</p>
          <div class="numbers">
            <UseElementVisibility v-slot="{ isVisible }">
              <count-up
                :autoplay="isVisible"
                :options="CountUpOptions"
                :startVal="0"
                :end-val="80"
              ></count-up>
            </UseElementVisibility>
            <p>More Metrics Here</p>
          </div>
          <hr />
          <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi
            tristique mattis nulla, in facilisis justo tincidunt eget.
          </p>
        </div>
      </div>
      <div class="services-container">
        <div data-aos="fade-up" class="title-section">
          <h1>Our Services</h1>
          <hr />
        </div>
        <div class="service-showcase">
          <div class="service-names">
            <h3 data-aos="fade-up">Explore:</h3>
            <hr />
            <div
              data-aos="fade-up"
              v-for="(service, i) in services"
              class="service"
              :key="service.title"
            >
              <div class="service-section">
                <p>
                  {{ service.title }}
                </p>
                <span
                  @click="toggleServiceDetails(i)"
                  v-show="currentService !== i"
                >
                  <img src="/images/icon/plus.svg" />
                </span>
              </div>
              <hr />
            </div>
            <div data-aos="fade-up" class="view-all">
              <router-link class="btn" to="/services">
                View All
                <img class="arrow-right" src="/images/icon/arrow-right.svg" />
              </router-link>
            </div>
          </div>
          <Transition name="fade">
            <div v-if="serviceAnimation" class="service-details">
              <img
                data-aos="fade-up"
                :src="currentServiceData.img"
                :alt="currentServiceData.title"
              />
              <div class="detail">
                <h2 data-aos="fade-up">
                  {{ currentServiceData.title }}
                </h2>
                <p data-aos="fade-up">
                  {{ currentServiceData.description }}
                </p>
                <div data-aos="fade-up" class="next">
                  <img
                    class="arrow-right-blue"
                    src="/images/icon/arrow-right-blue.svg"
                  />
                  <span
                    @click="
                      toggleServiceDetails(
                        currentService === services.length - 1
                          ? 0
                          : currentService + 1
                      )
                    "
                  >
                    Next
                  </span>
                </div>
              </div>
            </div>
          </Transition>
        </div>
      </div>
      <div class="more-details">
        <img
          v-bind:data-aos="isMobile ? 'fade-up' : 'fade-right'"
          src="/images/home-our-team.jpg"
          alt=""
        />
        <div class="details">
          <h1 data-aos="fade-up">Our Team</h1>
          <p data-aos="fade-up">
            Meet PCA's senior claims team who collectively drive the commitment,
            quality of work and service that our clients require.
          </p>
          <router-link data-aos="fade-up" class="btn" to="/team">
            Learn More
            <img class="arrow-right" src="/images/icon/arrow-right.svg" />
          </router-link>
        </div>
      </div>
      <div class="more-details second">
        <div class="details">
          <h1 data-aos="fade-up">Our Location</h1>
          <p data-aos="fade-up">
            With our Head Office in the Canada's Capital, we are complimented
            with two additional fully staffed branch offices in the cities of
            Kingston & Belleville.
          </p>
          <router-link data-aos="fade-up" class="btn" to="/contact">
            Learn More
            <img class="arrow-right" src="/images/icon/arrow-right.svg" />
          </router-link>
        </div>
        <img
          v-bind:data-aos="isMobile ? 'fade-up' : 'fade-left'"
          src="/images/home-our-location.jpg"
          alt=""
        />
      </div>
    </div>
    <footer-portal
      title="Client Portal"
      subtitle="PCA clients - login to review files on a case-by-case basis."
      buttonText="Sign in"
      buttonURL="#"
    />
  </div>
</template>

<script>
import VideoBackground from "../components/VideoBackground.vue";
import CountUp from "vue-countup-v3";
import FooterPortal from "../components/FooterPortal.vue";
import { UseElementVisibility } from "@vueuse/components";

export default {
  name: "Home",
  components: {
    VideoBackground,
    CountUp,
    FooterPortal,
    UseElementVisibility,
  },
  data() {
    return {
      CountUpOptions: {
        duration: 3,
        enableScrollSpy: true,
        scrollSpyDelay: 100,
      },
      serviceAnimation: true,
      currentService: 0,
      services: [
        {
          title: "Commercial Property",
          description:
            "With in-depth knowledge of commercial leases, contracts, and the workings of condominium corporations, our team of senior adjusters are available to assist you in all aspects of these types of claims.",
          img: "/images/services/11-commerc-liability.jpeg",
        },
        {
          title: "Long Haul Trucking",
          description:
            "Having been involved in the handling and claims management of over 30 fleets in our region, our team of adjusters are aware of the urgent need for prompt scene attendance and detailed investigations.",
          img: "/images/services/long-haul-trucking.jpeg",
        },
        {
          title: "Agriculture",
          description:
            "When a loss occurs at a farming operation, whether it involves a barn, livestock, heavy equipment or crops, the effect on the insured can be devastating. Our adjusters are well experienced in handling these types of losses.",
          img: "/images/services/03-agriculture.jpeg",
        },
        {
          title: "Environmental",
          description:
            "Be it a residential or commercial oil spill, or leakage of other contaminants, our senior adjusters are well versed and experienced in handling a variety of environmental losses.",
          img: "/images/services/04-environmental.jpeg",
        },
        {
          title: "Municipal Liability",
          description:
            "Appointed as control adjusters for numerous municipalities in the Eastern Ontario corridor, we have the background and experience to respond to and address municipal matters.",
          img: "/images/services/05-muni-liability.jpeg",
        },
        {
          title: "Pre & Post Blast Survey",
          description:
            "Our team of adjusters are fully experienced in completing these surveys, in order to ensure accurate documentation of neighbouring properties.",
          img: "/images/services/06-pre-post-blast.jpeg",
        },
        {
          title: "Accident Benefits",
          description:
            "Our experienced Accident Benefit adjusters are well aware of the strict timelines for these claims, and are pleased to assist you with these files, either on a task or full handle basis.",
          img: "/images/services/07-accident-benefits.jpeg",
        },
      ],
    };
  },
  methods: {
    toggleServiceDetails(index) {
      this.serviceAnimation = false;
      setTimeout(() => {
        this.currentService = index;
        this.serviceAnimation = true;
      }, 100);
    },
  },
  computed: {
    currentServiceData() {
      return this.services[this.currentService];
    },
    isMobile() {
      return window.innerWidth < 680;
    },
  },
};
</script>

<style scoped lang="scss">
.home-intro {
  .badge {
    display: block;
    position: absolute;
    margin: 0 auto;
    margin-top: -50px;
    width: 250px;
    z-index: 3;
    left: 50%;
    transform: translateX(-50%) !important;
  }
}
.title-container {
  margin-bottom: 100px;
  padding-bottom: 0;
  h2{
    margin-top: 80px;
  }
}
.service-stats {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  margin: 60px 40px;
}
.stat {
  padding: 0 20px;
  width: 33.33%;
}

.index {
  font-size: 14px;
  line-height: 16px;
}

.numbers {
  display: flex;
  align-items: center;
  ::v-deep .countup-wrap {
    span {
      font-size: 87px;
      line-height: 87px;
      font-weight: 700;
      color: #0267ff;
      margin-right: 10px;
    }
  }
  p {
    font-family: "dashiell-fine", serif;
    font-weight: 400;
    font-size: 18px;
  }
}

.services-container {
  background: url("/images/bg-home-services.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 100vh;
  color: #fff;
  padding: 60px;
  position: relative;
  z-index: 2;
  margin-bottom: 100px;
  hr {
    border: none;
    height: 1px;
    border-bottom: 1px solid #fff;
    opacity: 0.5;
    position: relative;
  }
}

.service-names {
  display: flex;
  flex-direction: column;
  width: 40%;
  padding: 10px 5%;
  h3 {
    text-transform: uppercase;
    font-weight: 500;
  }
  hr {
    width: 100%;
    opacity: 0.1;
  }
}

.service-showcase {
  display: flex;
  flex-wrap: wrap;
}
.service-section {
  display: flex;
  align-items: center;
  justify-content: space-between;
  p {
    text-transform: uppercase;
  }
  span {
    font-size: 30px;
    &:hover {
      cursor: pointer;
    }
  }
}
.view-all {
  display: flex;
  justify-content: flex-start;
  margin-top: 20px;
}

.service-details {
  flex: 1;
  padding: 0 5%;
  img {
    margin-top: -40px;
    width: 100%;
    z-index: -1;
  }
  .detail {
    padding-left: 40px;
    span {
      font-size: 30px;
      &:hover {
        cursor: pointer;
      }
    }
  }
}

.more-details {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 15%;
  margin-top: 175px;
  img {
    width: 50%;
  }
  .details {
    flex: 1;
    margin: 0 75px;
    h1 {
      margin-bottom: 0px;
    }
    p {
      margin-bottom: 20px;
    }
  }
  &.second {
    margin-top: 100px;
    margin-bottom: 175px;
  }
}

.next {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  .arrow-right-blue {
    height: 10px;
    margin-right: 10px;
    width: unset;
    display: inline-block;
    margin-top: 0;
  }
}

.fade-enter-active {
  transition: opacity 0 ease;
}
.fade-leave-active {
  transition: opacity 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

@media screen and (max-width: $mobile) {
  .title-section {
    h1 {
      font-size: 25px;
      line-height: 35px;
    }
    p {
      font-size: 16px;
    }
  }
  .stat {
    padding: 0 20px;
    width: 100%;
  }
  .numbers {
    justify-content: center;
  }
  .more-details {
    flex-wrap: wrap;
    flex-direction: column;
    &.second {
      flex-direction: column-reverse;
    }
    img {
      width: 100%;
    }
    .details {
      margin: 0;
    }
  }
  .service-names {
    display: none;
  }
  .service-details {
    padding: 0;
    img {
      margin-top: 40px;
    }
    .detail {
      padding-left: 0;
      text-align: center;
    }
  }
}
</style>
