<template>
  <Header />
  <notifications position="top center" />
  <router-view v-slot="{ Component }">
    <transition
      name="custom-fade"
      enter-active-class="animate__animated animate__fadeIn animate__faster"
    >
      <component :is="Component" />
    </transition>
  </router-view>
  <Footer />
</template>

<script>
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";
import "vueperslides/dist/vueperslides.css";
import "animate.css";
import "aos/dist/aos.css";

export default {
  name: "App",
  components: {
    Header,
    Footer,
  },
  mounted: function () {
    if (!this.$store.getters.worksLoaded) {
      this.$store.dispatch("getEmployees");
      this.$store.dispatch("getJobs");
    } else if (this.$store.getters.checkExpiryDate) {
      this.$store.dispatch("updateCurrentTime");
      this.$store.dispatch("getEmployees");
      this.$store.dispatch("getJobs");
    }
  },
};
</script>

<style lang="scss">
@import url("https://use.typekit.net/iji3aiu.css");
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300;500;600&display=swap");

html,
body {
  font-family: "dashiell-fine", serif;
  font-weight: 400;
  position: relative;
  margin: 0;
  padding: 0;
  height: 100%;
  min-height: 100vh;
  width: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
* {
  box-sizing: border-box;
}
h1 {
  font-size: 60px;
  line-height: 62.5px;
  font-weight: 400;
}
h2 {
  font-size: 40px;
  line-height: 42px;
  font-weight: 400;
}
h3 {
  font-weight: 400;
  font-size: 30px;
  line-height: 31.25px;
  letter-spacing: 1.05px;
}
p {
  font-family: $secondaryFont;
  font-weight: 300;
  font-size: 17px;
  line-height: 28px;
}
.btn,
button {
  display: inline-block;
  color: #fff;
  font-family: $secondaryFont;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  background: #0267ff;
  padding: 15px;
  min-width: 160px;
  text-decoration: none;
  text-align: center;
  img {
    vertical-align: bottom;
    margin: 0 10px;
  }
  .arrow-right {
    vertical-align: middle;
    width: 20px !important;
  }
  &:hover {
    background: #0267ff;
  }
}

.btn,
button:hover {
  cursor: pointer;
}

.title-container {
  text-align: center;
  padding-top: 60px;
  padding-bottom: 80px;
  margin: auto 40px;
  p {
    max-width: 800px;
    margin: 0 auto;
  }
}

.container-padding {
  margin: 20px;
}

@media screen and (max-height: $tabletHeight) {
}

@media screen and (max-width: $tabletWidth) {
}

@media screen and (max-width: $mobile) {
  .container-padding {
    margin: 0;
  }
  h1 {
    font-size: 60px;
    line-height: 70px;
  }
  h2 {
    font-size: 35px;
    line-height: 45px;
  }
}

.ca3-scroll-down-link {
  cursor: pointer;
  line-height: 60px;
  left: 50%;
  transform: translateX(-50%);
  bottom: 20px;
  color: #fff;
  text-align: center;
  z-index: 5;
  animation: ca3_fade_move_down 2.5s ease-in-out infinite;
  img {
    margin: 0 auto;
    width: 128px;
  }
}

@keyframes ca3_fade_move_down {
  0% {
    transform: translate(0, -20px);
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    transform: translate(0, 20px);
    opacity: 0;
  }
}

.vueperslides__arrow {
  opacity: 1;
  &:hover {
    opacity: 0.7;
  }
}

.vue-notification-group {
  top: 90px;
}

.vue-notification {
  font-family: $secondaryFont;
}
</style>
