<template>
  <div class="portal">
    <h1>
      {{ title }}
    </h1>
    <p>
      {{ subtitle }}
    </p>
    <a class="btn" :href="buttonURL">{{ buttonText }}</a>
  </div>
</template>

<script>
export default {
  name: "FooterPortal",
  props: {
    title: String,
    subtitle: String,
    buttonText: String,
    buttonURL: String,
  },
};
</script>

<style scoped lang="scss">
.portal {
  background: #e5edf9;
  text-align: center;
  padding: 60px 0;
  h1 {
    margin: 32px 20px;
  }
  p {
    margin: 32px 20px;
  }
  .btn {
    margin: 16px 0 32px;
  }
}
</style>
