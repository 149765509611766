<template>
  <Menu />
</template>

<script>
import Menu from "./Menu.vue";
export default {
  name: "Header",
  components: {
    Menu,
  },
};
</script>
