import { createStore } from "vuex";
import axios from "axios";

const TWO_HOURS = 2 * 60 * 60 * 1000;

export default createStore({
  state: {
    employees: [],
    expiryDate: new Date(),
    jobs: [],
  },
  getters: {
    employees: (state) => {
      return state.employees;
    },
    checkExpiryDate: (state) => {
      return new Date() - state.expiryDate > TWO_HOURS;
    },
    employeesLoaded: (state) => {
      return state.employees.length > 0;
    },
    jobs: (state) => {
      return state.jobs;
    },
    jobsLoaded: (state) => {
      return state.jobs.length > 0;
    },
  },
  mutations: {
    getEmployees(state) {
      axios
        .get("https://strapi.uptowncreative.io/pca-adjusters-employees")
        .then((response) => {
          state.employees = response.data;
        });
    },
    getJobs(state) {
      axios
        .get("https://strapi.uptowncreative.io/pca-adjusters-jobs?_sort=DisplayOrder:asc")
        .then((response) => {
          state.jobs = response.data;
        });
    },
    updateCurrentTime(state) {
      state.expiryDate = new Date();
    },
  },
  actions: {
    getEmployees(context) {
      context.commit("getEmployees");
    },
    getJobs(context) {
      context.commit("getJobs");
    },
    updateCurrentTime(context) {
      context.commit("updateCurrentTime");
    },
  },
  modules: {},
});
